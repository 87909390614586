import PageCategory from '../components/PageCategory'
import { graphql } from 'gatsby'

export const query = graphql`
  query PageCategory($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    articles: allMdx(
      filter: {
        fields: {
          categories: { in: [$slug] }
          sourceName: { eq: "blog-posts" }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            categories
            id
            postTitle
            date
            image {
              childImageSharp {
                gatsbyImageData(width: 250)
              }
            }
            tags
            tags_private
          }
        }
      }
    }
    category: mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        color
        definition
        id
        image {
          childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
          }
        }
        featured_listings
        search_suggestions
        page_description
        page_title
        tags
        title
        tool_title
        input_location
        input_search
      }
    }
  }
`

export default PageCategory
