import AddressAutofill from '../AddressAutofill'
import Popover from '../Popover'
import Template from '../Template'
import * as style from './style.module.scss'
import { navigate } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import { connectSearchBox } from 'react-instantsearch-dom'
import {
  unstable_useFormState as useFormState,
  unstable_FormLabel as FormLabel,
} from 'reakit/Form'
import { usePopoverState, PopoverDisclosure } from 'reakit/Popover'

function PageCategory(props) {
  const category = props.data.category
  const categoryTitle = category ? category.frontmatter.title : 'Categories'
  const { site } = props.data
  const popoverTooltip = usePopoverState()
  const form = useFormState({})
  const locationRef = React.useRef()

  return (
    <Template>
      <Helmet>
        <title>
          {categoryTitle} - {site.siteMetadata.title}
        </title>
      </Helmet>

      <div className={style.hero}>
        <div className={style.pageTitleContainer}>
          <h1 className={style.h1}>{category.frontmatter.page_title}</h1>
          {/* <p className={style.subline}>
            {category.frontmatter.page_description}
          </p> */}
        </div>
        <div className={style.formContainer}>
          <form
            {...form}
            noValidate={false}
            className={style.form}
            onSubmit={(event) => {
              event.preventDefault()
              const data = new FormData(event.target)
              props.refine(data.get('query'))
              navigate(
                `/search/?l=${data.get('l')}&c=${
                  props.pageContext.slug
                }&query=${data.get('query')}`
              )
            }}
          >
            <div className={style.inputRow}>
              <div className={style.inputGroup}>
                <FormLabel>
                  {category.frontmatter.input_location}
                  <PopoverDisclosure
                    className={style.popoverIcon}
                    {...popoverTooltip}
                  >
                    <span>?</span>
                  </PopoverDisclosure>
                  <Popover
                    {...popoverTooltip}
                    aria-label='Why do we ask for this?'
                  >
                    You can search by your address, city, state, or zip code.
                    We'll try to show you the closest results first.
                  </Popover>
                </FormLabel>

                <div className={style.inputWrapper}>
                  <AddressAutofill
                    {...form}
                    placeholder='Your address, city, or zip code'
                    name='city'
                    placeSelect={(place) => {
                      locationRef.current.value = `${place.properties.lat},${place.properties.lon}`
                    }}
                    required
                  />
                  <input ref={locationRef} type='hidden' name='l' />
                </div>
              </div>
            </div>
            <div className={style.inputRow}>
              <div className={style.inputGroup}>
                <FormLabel>{category.frontmatter.input_search} </FormLabel>
                <div className={style.dates}>
                  <input
                    type='date'
                    placeholder='Select start date'
                    name='startDate'
                  />
                  <input
                    type='date'
                    placeholder='Select end date'
                    name='endDate'
                  />
                </div>
              </div>
            </div>
            <button
              className={`${style.button} ${category.frontmatter.color}`}
              type='submit'
            >
              Continue
            </button>
          </form>
        </div>
      </div>
    </Template>
  )
}

export default connectSearchBox(PageCategory)
