import '@geoapify/geocoder-autocomplete/styles/minimal.css'
import React from 'react'

const GeoapifyGeocoderAutocomplete = React.lazy(() =>
  import('@geoapify/react-geocoder-autocomplete').then(
    ({ GeoapifyGeocoderAutocomplete }) => ({
      default: GeoapifyGeocoderAutocomplete,
    })
  )
)

const GeoapifyContext = React.lazy(() =>
  import('@geoapify/react-geocoder-autocomplete').then(
    ({ GeoapifyContext }) => ({
      default: GeoapifyContext,
    })
  )
)

export default function AddressAutofill(props) {
  if (typeof window === 'undefined') {
    return null
  } else {
    return (
      <React.Suspense fallback={null}>
        <GeoapifyContext apiKey="72f98deec1634d869f912f98f45818f5">
          <GeoapifyGeocoderAutocomplete
            filterByCountryCode={['us']}
            debounceDelay={50}
            {...props}
          />
        </GeoapifyContext>
      </React.Suspense>
    )
  }
}
